define("discourse/plugins/discourse-ai-topic-summary/discourse/templates/connectors/topic-above-posts/topic-above-posts", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <TopOfTopicAttachment @model={{this.model}} @currentUser={{this.currentUser}} />
  */
  {
    "id": "XB7nHTgx",
    "block": "[[[8,[39,0],null,[[\"@model\",\"@currentUser\"],[[30,0,[\"model\"]],[30,0,[\"currentUser\"]]]],null]],[],false,[\"top-of-topic-attachment\"]]",
    "moduleName": "discourse/plugins/discourse-ai-topic-summary/discourse/templates/connectors/topic-above-posts/topic-above-posts.hbs",
    "isStrictMode": false
  });
});