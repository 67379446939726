define("discourse/plugins/discourse-ai-topic-summary/discourse/components/top-of-topic-attachment", ["exports", "@glimmer/component", "@ember/service", "discourse/plugins/discourse-ai-topic-summary/discourse/components/ai-topic-summary", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _aiTopicSummary, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class TopOfTopicAttachment extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    get relyOnSidebarWidgetInstead() {
      return this.siteSettings.ai_topic_summary_rely_on_sidebar_widget_instead;
    }
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#unless this.relyOnSidebarWidgetInstead}}
          <AiTopicSummary
            @text={{@model.ai_summary.text}}
            @topic_id={{@model.id}}
            @downVotes={{@model.ai_summary.downvoted}}
            @currentUser={{@currentUser}}
          />
        {{/unless}}
      
    */
    {
      "id": "G2SW3P8a",
      "block": "[[[1,\"\\n\"],[41,[51,[30,0,[\"relyOnSidebarWidgetInstead\"]]],[[[1,\"      \"],[8,[32,0],null,[[\"@text\",\"@topic_id\",\"@downVotes\",\"@currentUser\"],[[30,1,[\"ai_summary\",\"text\"]],[30,1,[\"id\"]],[30,1,[\"ai_summary\",\"downvoted\"]],[30,2]]],null],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@model\",\"@currentUser\"],false,[\"unless\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai-topic-summary/discourse/components/top-of-topic-attachment.js",
      "scope": () => [_aiTopicSummary.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = TopOfTopicAttachment;
});